<template>
  <div class="step__body">
    <h3>{{ step.name }}</h3>
    <p>{{ step.description }}</p>
    <div class="row option">
      <div class="col-md-6">
        <div class="card">
          <img class="m-4" :src="$filters.image('image-service:/production/56d032a155699.jpg')">
          <div class="card-body">
            <select class="form-select" @change="onChange" v-model="width">
              <option v-for="selectOption in widthOptions" :key="selectOption" :value="selectOption" :selected="selectOption === selectedOption.width">
                {{ selectOption }} {{ step.unit }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <img class="m-4" :src="$filters.image('image-service:/production/56d0329d35428.jpg')">
            <div class="card-body">
              <select class="form-select" @change="onChange" v-model="height">
                <option v-for="selectOption in heightOptions" :key="selectOption" :value="selectOption" :selected="selectOption === selectedOption.height">
                  {{ selectOption }} {{ step.unit }}
                </option>
              </select>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
import _ from 'lodash'

export default {
  setup () {
    const store = useStore()
    const height = ref(0)
    const width = ref(0)
    const currentType = ref('width')

    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const selectedOption = computed(() => {
      return step.value.options.find(o => o.selected)
    })
    const relevantOptions = computed(() => {
      return step.value.options.filter(o => {
        return (o.height === height.value || o.width === width.value) && o.height > 0 && o.width > 0
      })
    })
    const widthOptions = computed(() => {
      return _.uniq(relevantOptions.value.map(o => o.width).sort((a, b) => a - b))
    })
    const heightOptions = computed(() => {
      return _.uniq(relevantOptions.value.map(o => o.height).sort((a, b) => a - b))
    })
    function onChange (e) {
      store.dispatch('configurator/selectDimensionTable', { height: Number.parseInt(height.value), width: Number.parseInt(width.value) })
    }
    onMounted(() => {
      width.value = Number.parseInt(selectedOption.value.width)
      height.value = Number.parseInt(selectedOption.value.height)
    })
    return {
      step,
      height,
      width,
      currentType,
      selectedOption,
      widthOptions,
      heightOptions,
      onChange
    }
  }
}
</script>
